<template>
  <div id="app">
    <editor
      api-key="no-api-key"
      :value="value"
      @input="$emit('input', $event)"
      :init="{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor forecolor| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat |  charmap  code'
      }"
    />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";

export default {
  props: {
    value: {
      required: false,
      default: "",
      type: String
    }
  },

  components: {
    editor: Editor
  }
};
</script>
