var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('editor',{attrs:{"api-key":"no-api-key","value":_vm.value,"init":{
      height: 500,
      menubar: false,
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code wordcount'
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor forecolor| alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat |  charmap  code'
    }},on:{"input":function($event){return _vm.$emit('input', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }