<template>
  <div>
    <div v-if="editMode">
      <v-text-field
        v-if="!hiddeTitle"
        class="mb-3"
        v-model="titleValue"
        label="Title"
        placeholder="Title"
        outlined
        clearable
        :disabled="titleDisabled"
      />

      <text-editor v-model="htmlValue" />
    </div>
    <div v-else>
      <h3>{{ titleValue }}</h3>
      <div v-html="htmlValue" />
    </div>

    <v-btn class="my-5" @click="editTogle">
      {{ editMode ? "Preview" : "Edit" }}
    </v-btn>
  </div>
</template>
<script>
import TextEditor from "./Tiptap";

export default {
  components: {
    TextEditor
  },

  props: {
    html: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },

    titleDisabled: {
      type: Boolean,
      default: false
    },

    hiddeTitle: {
      type: Boolean,
      default: false
    },

    edit: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      editMode: true,
      titleValue: "",
      htmlValue: ""
    };
  },

  watch: {
    edit(e) {
      if (e !== this.editMode) {
        this.editMode = e;
      }
    },

    html() {
      this.htmlValue = this.html;
    },

    title(value) {
      this.titleValue = value;
    },

    titleValue(value) {
      this.$emit("title", value);
    },

    htmlValue(value) {
      this.$emit("html", value);
    }
  },

  methods: {
    editTogle() {
      this.editMode = !this.editMode;
    }
  },

  mounted() {
    this.editMode = this.edit;
    this.htmlValue = this.html;
    this.titleValue = this.title;
  }
};
</script>
